import { get } from 'es-toolkit/compat'

export const optionsShared = {
  tableName: [
    {
      value: 'apikeys',
      label: 'Claves API',
      description: 'Las Claves API permiten acceder al sistema desde aplicaciones externas de manera segura. Cada clave tiene permisos configurables para proteger la información y las operaciones.',
      icon: 'i-mdi-key',
    },
    {
      value: 'accounts',
      label: 'Cuentas',
      description: 'Una Cuenta es un registro de empresa o entidad con la que se tiene relación comercial. Puede representar clientes, proveedores o socios. Cada cuenta puede tener múltiples contactos y negocios asociados.',
      icon: 'i-mdi-office-building',
    },
    {
      value: 'activities',
      label: 'Actividades',
      description: 'Las Actividades son registros de interacciones comerciales tante entrada como de salida.',
      icon: 'i-mdi-calendar',
    },
    {
      value: 'applications',
      label: 'Aplicaciones',
      description: 'Las Aplicaciones son registros de casos de uso de los productos o servicios de la empresa.',
      icon: 'i-mdi-apps',
    },
    {
      value: 'auditlogs',
      label: 'Registros de Auditoría',
      description: 'Los Registros de Auditoría documentan cambios y eventos importantes en el sistema. Incluyen información como el usuario, la acción realizada y la fecha.',
      icon: 'i-mdi-history',
    },
    {
      value: 'banks',
      label: 'Bancos',
      description: 'Los Bancos representan instituciones financieras asociadas con las operaciones de la empresa.',
      icon: 'i-mdi-bank',
    },
    {
      value: 'branches',
      label: 'Sucursales',
      description: 'Las Sucursales son registros de las ubicaciones físicas de los clientes, proveedores o socios de la empresa.',
      icon: 'i-mdi-map-marker',
    },
    {
      value: 'campaigns',
      label: 'Campañas',
      description: 'Las Campañas son iniciativas de marketing o ventas diseñadas para alcanzar objetivos específicos. Todos los Negocios requieren una campaña asociada.',
      icon: 'i-mdi-bullhorn',
    },
    {
      value: 'categories',
      label: 'Categorías',
      description: 'Las Categorías se utilizan para organizar y agrupar de manera eficiente productos y servicios facilitando su clasificación, búsqueda y gestión dentro del sistema',
      icon: 'i-mdi-label-multiple',
    },
    {
      value: 'contacts',
      label: 'Contactos',
      description: 'Los Contactos representan personas o entidades con las que la empresa tiene relaciones comerciales.',
      icon: 'i-mdi-account-group',
    },
    {
      value: 'deals',
      label: 'Negocios',
      description: 'Los Negocios representan registros de oportunidades comerciales que pueden asociarse a contactos, actividades y cotizaciones, facilitando un seguimiento detallado y una gestión organizada de cada caso.',
      icon: 'i-mdi-tag',
    },
    {
      value: 'financialaccounts',
      label: 'Cuentas Financieras',
      description: 'Las Cuentas Financieras gestionan activos monetarios de la empresa, como cuentas bancarias o cajas.',
      icon: 'i-mdi-cash-register',
    },
    {
      value: 'industries',
      label: 'Industrias',
      description: 'Las Industrias agrupan empresas según el sector económico al que pertenecen. El catálogo de industrias facilita la segmentación y análisis de clientes y cuentas.',
      icon: 'i-mdi-office-building',
    },
    {
      value: 'issues',
      label: 'Incidencias',
      description: 'Las Incidencias documentan problemas o errores reportados dentro del sistema. Se gestionan para resolverlos de manera eficiente.',
      icon: 'i-mdi-radiobox-marked',
    },
    {
      value: 'notes',
      label: 'Notas',
      description: 'Las Notas son comentarios o recordatorios relacionados con contactos, negocios, o actividades. Facilitan el seguimiento de información relevante.',
      icon: 'i-mdi-note-text',
    },
    {
      value: 'notifications',
      label: 'Notificaciones',
      description: 'Las Notificaciones informan a los usuarios sobre eventos importantes o cambios en el sistema. Pueden ser configuradas para eventos específicos.',
      icon: 'i-mdi-bell',
    },
    {
      value: 'pricelists',
      label: 'Listas de Precios',
      description: 'Las Listas de Precios definen precios para productos y servicios.',
      icon: 'i-mdi-view-list',
    },
    {
      value: 'pricelistitems',
      label: 'Items',
      labelFull: 'Items de Listas de Precios',
      description: 'Los Precios detallan valores individuales dentro de una lista de precios.',
      icon: 'i-mdi-barcode',
    },
    {
      value: 'productionorders',
      label: 'Órdenes de Producción',
      description: 'Las Órdenes de Producción organizan los procesos de manufactura. Incluyen detalles de productos a fabricar y materiales necesarios.',
      icon: 'i-mdi-package-variant-closed',
    },
    {
      value: 'productionorderitems',
      label: 'Items',
      labelFull: 'Items de Órdenes de Producción',
      description: 'Los Items de Órdenes de Producción especifican componentes o productos individuales dentro de una orden de producción.',
      icon: 'i-mdi-barcode',
    },
    {
      value: 'products',
      label: 'Productos',
      description: 'Los Productos son bienes físicos que la empresa ofrece.',
      icon: 'i-mdi-package-variant-closed',
    },
    {
      value: 'productitems',
      label: 'Items',
      labelFull: 'Items de Productos',
      description: 'Los Items de Productos representan unidades específicas o variaciones de un producto.',
      icon: 'i-mdi-barcode',
    },
    {
      value: 'quotes',
      label: 'Cotizaciones',
      description: 'Las Cotizaciones representan propuestas de precios enviadas a clientes. Incluyen detalles de productos o servicios ofrecidos y sus precios.',
      icon: 'i-mdi-file-document-edit',
    },
    {
      value: 'quoteitems',
      label: 'Items',
      labelFull: 'Items de Cotizaciones',
      description: 'Los Items de Cotizaciones son elementos individuales dentro de una cotización, como productos o servicios específicos.',
      icon: 'i-mdi-barcode',
    },
    {
      value: 'salesorders',
      label: 'Órdenes de Venta',
      description: 'Las Órdenes de Venta documentan pedidos confirmados de clientes. Incluyen detalles de productos, cantidades y precios finales.',
      icon: 'i-mdi-cart',
    },
    {
      value: 'salesorderitems',
      label: 'Items',
      labelFull: 'Items de Órdenes de Venta',
      description: 'Los Items de Órdenes de Venta son los productos o servicios específicos incluidos en una orden de venta.',
      icon: 'i-mdi-barcode',
    },
    {
      value: 'services',
      label: 'Servicios',
      description: 'Los Servicios son servicios ofrecidas a los clientes.',
      icon: 'i-mdi-tools',
    },
    {
      value: 'serviceitems',
      label: 'Items',
      labelFull: 'Items de Servicios',
      description: 'Los Items de Servicios representan variantes o detalles específicos de un servicio.',
      icon: 'i-mdi-barcode',
    },
    {
      value: 'tasks',
      label: 'Tareas',
      description: 'Las Tareas son actividades asignadas a usuarios o equipos dentro del sistema. Facilitan el seguimiento de responsabilidades y plazos.',
      icon: 'i-mdi-checkbox-marked-circle',
    },
    {
      value: 'transactions',
      label: 'Transacciones',
      description: 'Las Transacciones documentan movimientos financieros realizados en el sistema. Incluyen pagos, transferencias y ajustes contables.',
      icon: 'i-mdi-swap-horizontal-circle',
    },
    {
      value: 'users',
      label: 'Usuarios',
      description: 'Los Usuarios son las personas que tienen acceso al sistema. Cada usuario puede tener roles y permisos específicos.',
      icon: 'i-mdi-account',
    },
    {
      value: 'usernotifications',
      label: 'Notificaciones',
      description: 'Las Notificaciones informan a los usuarios sobre eventos importantes o cambios en el sistema. Pueden ser configuradas para eventos específicos.',
      icon: 'i-mdi-bell',
    },
  ].toSorted((a, b) => a.value.localeCompare(b.value)),
  alert: [
    { value: 'info', label: 'Información', icon: 'i-mdi-information', color: 'blue' },
    { value: 'warning', label: 'Advertencia', icon: 'i-mdi-alert', color: 'amber' },
    { value: 'error', label: 'Error', icon: 'i-mdi-alert-circle', color: 'red' },
    { value: 'success', label: 'Éxito', icon: 'i-mdi-check-circle', color: 'green' },
  ],
  page: [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 250, label: '250' },
  ],
  // from https://gist.github.com/keeguon/231###8?permalink_comment_id=4970109#gistcomment-4970109
  countryCode: [
    { value: 'AF', label: 'Afganistan', code: '93', maskPhone: '## ### ####', icon: 'i-circle-flags-af' },
    { value: 'AL', label: 'Albania', code: '355', maskPhone: '## ### ####', icon: 'i-circle-flags-al' },
    { value: 'DE', label: 'Alemania', code: '49', maskPhone: '#### #######', icon: 'i-circle-flags-de' },
    { value: 'AD', label: 'Andorra', code: '376', maskPhone: '### ###', icon: 'i-circle-flags-ad' },
    { value: 'AO', label: 'Angola', code: '244', maskPhone: '### ### ###', icon: 'i-circle-flags-ao' },
    { value: 'AI', label: 'Anguila', code: '1', maskPhone: '(264) ### ####', icon: 'i-circle-flags-ai' },
    { value: 'AQ', label: 'Antartida', code: '672', maskPhone: '# ### ###', icon: 'i-circle-flags-aq' },
    { value: 'AG', label: 'Antigua y Barbuda', code: '1', maskPhone: '(268) ### ####', icon: 'i-circle-flags-ag' },
    { value: 'SA', label: 'Arabia Saudita', code: '966', maskPhone: '# ### ####', icon: 'i-circle-flags-sa' },
    { value: 'DZ', label: 'Argelia', code: '213', maskPhone: '### ## ## ##', icon: 'i-circle-flags-dz' },
    { value: 'AR', label: 'Argentina', code: '54', maskPhone: '# #### ####', icon: 'i-circle-flags-ar' },
    { value: 'AM', label: 'Armenia', code: '374', maskPhone: '## ### ###', icon: 'i-circle-flags-am' },
    { value: 'AW', label: 'Aruba', code: '297', maskPhone: '### ####', icon: 'i-circle-flags-aw' },
    { value: 'AU', label: 'Australia', code: '61', maskPhone: '# #### ####', icon: 'i-circle-flags-au' },
    { value: 'AT', label: 'Austria', code: '43', maskPhone: '#### ####', icon: 'i-circle-flags-at' },
    { value: 'AZ', label: 'Azerbaiyan', code: '994', maskPhone: '## ### ## ##', icon: 'i-circle-flags-az' },
    { value: 'BS', label: 'Bahamas', code: '1', maskPhone: '(242) ### ####', icon: 'i-circle-flags-bs' },
    { value: 'BD', label: 'Banglades', code: '880', maskPhone: '## ### ###', icon: 'i-circle-flags-bd' },
    { value: 'BB', label: 'Barbados', code: '1', maskPhone: '(246) ### ####', icon: 'i-circle-flags-bb' },
    { value: 'BH', label: 'Barein', code: '973', maskPhone: '#### ####', icon: 'i-circle-flags-bh' },
    { value: 'BE', label: 'Belgica', code: '32', maskPhone: '### ### ###', icon: 'i-circle-flags-be' },
    { value: 'BZ', label: 'Belice', code: '501', maskPhone: '### ####', icon: 'i-circle-flags-bz' },
    { value: 'BJ', label: 'Benin', code: '229', maskPhone: '## ## ## ##', icon: 'i-circle-flags-bj' },
    { value: 'BM', label: 'Bermudas', code: '1', maskPhone: '(441) ### ####', icon: 'i-circle-flags-bm' },
    { value: 'BY', label: 'Bielorrusia', code: '375', maskPhone: '## ### ## ##', icon: 'i-circle-flags-by' },
    { value: 'BO', label: 'Bolivia (Estado Plurinacional De)', code: '591', maskPhone: '# #### ####', icon: 'i-circle-flags-bo' },
    { value: 'BQ', label: 'Bonaire, San Eustaquio y Saba', code: '599', maskPhone: '', icon: 'i-circle-flags-bq' },
    { value: 'BA', label: 'Bosnia y Herzegovina', code: '387', maskPhone: '## ######', icon: 'i-circle-flags-ba' },
    { value: 'BW', label: 'Botsuana', code: '267', maskPhone: '## ### ###', icon: 'i-circle-flags-bw' },
    { value: 'BR', label: 'Brasil', code: '55', maskPhone: '## ##### ####', icon: 'i-circle-flags-br' },
    { value: 'BN', label: 'Brunei Darussalam', code: '673', maskPhone: '### ####', icon: 'i-circle-flags-bn' },
    { value: 'BG', label: 'Bulgaria', code: '359', maskPhone: '### ### ###', icon: 'i-circle-flags-bg' },
    { value: 'BF', label: 'Burkina Faso', code: '226', maskPhone: '## ## ## ##', icon: 'i-circle-flags-bf' },
    { value: 'BI', label: 'Burundi', code: '257', maskPhone: '## ## ####', icon: 'i-circle-flags-bi' },
    { value: 'BT', label: 'Butan', code: '975', maskPhone: '# ### ###', icon: 'i-circle-flags-bt' },
    { value: 'CV', label: 'Cabo Verde', code: '238', maskPhone: '### ## ##', icon: 'i-circle-flags-cv' },
    { value: 'KH', label: 'Camboya', code: '855', maskPhone: '## ### ###', icon: 'i-circle-flags-kh' },
    { value: 'CM', label: 'Camerun', code: '237', maskPhone: '#### ####', icon: 'i-circle-flags-cm' },
    { value: 'CA', label: 'Canada', code: '1', maskPhone: '(###) ### ####', icon: 'i-circle-flags-ca' },
    { value: 'QA', label: 'Catar', code: '974', maskPhone: '#### ####', icon: 'i-circle-flags-qa' },
    { value: 'TD', label: 'Chad', code: '235', maskPhone: '## ## ## ##', icon: 'i-circle-flags-td' },
    { value: 'CL', label: 'Chile', code: '56', maskPhone: '# #### ####', icon: 'i-circle-flags-cl' },
    { value: 'CN', label: 'China', code: '86', maskPhone: '### #### ###', icon: 'i-circle-flags-cn' },
    { value: 'CY', label: 'Chipre', code: '357', maskPhone: '## ### ###', icon: 'i-circle-flags-cy' },
    { value: 'VA', label: 'Ciudad Del Vaticano', code: '379', maskPhone: '', icon: 'i-circle-flags-va' },
    { value: 'CO', label: 'Colombia', code: '57', maskPhone: '# ### ####', icon: 'i-circle-flags-co' },
    { value: 'KM', label: 'Comoras', code: '269', maskPhone: '## ## ## ##', icon: 'i-circle-flags-km' },
    { value: 'CG', label: 'Congo', code: '242', maskPhone: '## ### ###', icon: 'i-circle-flags-cg' },
    { value: 'CD', label: 'Congo (Republica Democratica Del)', code: '243', maskPhone: '', icon: 'i-circle-flags-cd' },
    { value: 'KR', label: 'Corea (Republica De)', code: '82', maskPhone: '## ### ####', icon: 'i-circle-flags-kr' },
    { value: 'KP', label: 'Corea (Republica Popular Democratica De)', code: '850', maskPhone: '### ### ####', icon: 'i-circle-flags-kp' },
    { value: 'CI', label: 'Costa de Marfil', code: '225', maskPhone: '## ### ###', icon: 'i-circle-flags-ci' },
    { value: 'CR', label: 'Costa Rica', code: '506', maskPhone: '#### ####', icon: 'i-circle-flags-cr' },
    { value: 'HR', label: 'Croacia', code: '385', maskPhone: '## ### ####', icon: 'i-circle-flags-hr' },
    { value: 'CU', label: 'Cuba', code: '53', maskPhone: '# ### ####', icon: 'i-circle-flags-cu' },
    { value: 'CW', label: 'Curazao', code: '599', maskPhone: '', icon: 'i-circle-flags-cw' },
    { value: 'DK', label: 'Dinamarca', code: '45', maskPhone: '## ## ## ##', icon: 'i-circle-flags-dk' },
    { value: 'DM', label: 'Dominica', code: '1', maskPhone: '### ####', icon: 'i-circle-flags-dm' },
    { value: 'EC', label: 'Ecuador', code: '593', maskPhone: '### ### ###', icon: 'i-circle-flags-ec' },
    { value: 'EG', label: 'Egipto', code: '20', maskPhone: '## ### ####', icon: 'i-circle-flags-eg' },
    { value: 'SV', label: 'El Salvador', code: '503', maskPhone: '## ## ## ##', icon: 'i-circle-flags-sv' },
    { value: 'AE', label: 'Emiratos Arabes Unidos', code: '971', maskPhone: '## ### ####', icon: 'i-circle-flags-ae' },
    { value: 'ER', label: 'Eritrea', code: '291', maskPhone: '# ### ###', icon: 'i-circle-flags-er' },
    { value: 'SK', label: 'Eslovaquia', code: '421', maskPhone: '### ### ###', icon: 'i-circle-flags-sk' },
    { value: 'SI', label: 'Eslovenia', code: '386', maskPhone: '## ### ###', icon: 'i-circle-flags-si' },
    { value: 'ES', label: 'España', code: '34', maskPhone: '### ### ###', icon: 'i-circle-flags-es' },
    { value: 'US', label: 'Estados Unidos de America', code: '1', maskPhone: '### ### ####', icon: 'i-circle-flags-us' },
    { value: 'EE', label: 'Estonia', code: '372', maskPhone: '### ####', icon: 'i-circle-flags-ee' },
    { value: 'SZ', label: 'Esuatini', code: '268', maskPhone: '', icon: 'i-circle-flags-sz' },
    { value: 'ET', label: 'Etiopia', code: '251', maskPhone: '## ### ####', icon: 'i-circle-flags-et' },
    { value: 'RU', label: 'Federacion de Rusia', code: '7', maskPhone: '', icon: 'i-circle-flags-ru' },
    { value: 'PH', label: 'Filipinas', code: '63', maskPhone: '### ### ####', icon: 'i-circle-flags-ph' },
    { value: 'FI', label: 'Finlandia', code: '358', maskPhone: '## ### ####', icon: 'i-circle-flags-fi' },
    { value: 'FJ', label: 'Fiyi', code: '679', maskPhone: '### ####', icon: 'i-circle-flags-fj' },
    { value: 'FR', label: 'Francia', code: '33', maskPhone: '# ## ## ## ##', icon: 'i-circle-flags-fr' },
    { value: 'GA', label: 'Gabon', code: '241', maskPhone: '# ## ## ##', icon: 'i-circle-flags-ga' },
    { value: 'GM', label: 'Gambia', code: '220', maskPhone: '### ####', icon: 'i-circle-flags-gm' },
    { value: 'GE', label: 'Georgia', code: '995', maskPhone: '### ### ###', icon: 'i-circle-flags-ge' },
    { value: 'GS', label: 'Georgia Del Sur E Islas Sandwich Del Sur', code: '500', maskPhone: '', icon: 'i-circle-flags-gs' },
    { value: 'GH', label: 'Ghana', code: '233', maskPhone: '### ### ###', icon: 'i-circle-flags-gh' },
    { value: 'GI', label: 'Gibraltar', code: '350', maskPhone: '', icon: 'i-circle-flags-gi' },
    { value: 'GD', label: 'Granada', code: '1', maskPhone: '', icon: 'i-circle-flags-gd' },
    { value: 'GR', label: 'Grecia', code: '30', maskPhone: '### ### ####', icon: 'i-circle-flags-gr' },
    { value: 'GL', label: 'Groenlandia', code: '299', maskPhone: '', icon: 'i-circle-flags-gl' },
    { value: 'GP', label: 'Guadalupe', code: '590', maskPhone: '', icon: 'i-circle-flags-gp' },
    { value: 'GU', label: 'Guam', code: '1', maskPhone: '', icon: 'i-circle-flags-gu' },
    { value: 'GT', label: 'Guatemala', code: '502', maskPhone: '# #### ####', icon: 'i-circle-flags-gt' },
    { value: 'GF', label: 'Guayana Francesa', code: '594', maskPhone: '', icon: 'i-circle-flags-gf' },
    { value: 'GG', label: 'Guernesey', code: '44', maskPhone: '', icon: 'i-circle-flags-gg' },
    { value: 'GN', label: 'Guinea', code: '224', maskPhone: '', icon: 'i-circle-flags-gn' },
    { value: 'GQ', label: 'Guinea Ecuatorial', code: '240', maskPhone: '', icon: 'i-circle-flags-gq' },
    { value: 'GW', label: 'Guinea-Bisau', code: '245', maskPhone: '', icon: 'i-circle-flags-gw' },
    { value: 'GY', label: 'Guyana', code: '592', maskPhone: '', icon: 'i-circle-flags-gy' },
    { value: 'HT', label: 'Haiti', code: '509', maskPhone: '', icon: 'i-circle-flags-ht' },
    { value: 'HN', label: 'Honduras', code: '504', maskPhone: '', icon: 'i-circle-flags-hn' },
    { value: 'HK', label: 'Hong Kong', code: '852', maskPhone: '#### ####', icon: 'i-circle-flags-hk' },
    { value: 'HU', label: 'Hungria', code: '36', maskPhone: '## ### ####', icon: 'i-circle-flags-hu' },
    { value: 'IN', label: 'India', code: '91', maskPhone: '##### #####', icon: 'i-circle-flags-in' },
    { value: 'ID', label: 'Indonesia', code: '62', maskPhone: '### ### ###', icon: 'i-circle-flags-id' },
    { value: 'IQ', label: 'Irak', code: '964', maskPhone: '', icon: 'i-circle-flags-iq' },
    { value: 'IR', label: 'Iran (Republica Islamica Del)', code: '98', maskPhone: '', icon: 'i-circle-flags-ir' },
    { value: 'IE', label: 'Irlanda', code: '353', maskPhone: '## ### ####', icon: 'i-circle-flags-ie' },
    { value: 'BV', label: 'Isla Bouvet', code: '47', maskPhone: '', icon: 'i-circle-flags-bv' },
    { value: 'IM', label: 'Isla de Man', code: '44', maskPhone: '', icon: 'i-circle-flags-im' },
    { value: 'CX', label: 'Isla de Navidad', code: '61', maskPhone: '', icon: 'i-circle-flags-cx' },
    { value: 'NF', label: 'Isla Norfolk', code: '672', maskPhone: '', icon: 'i-circle-flags-nf' },
    { value: 'IS', label: 'Islandia', code: '354', maskPhone: '', icon: 'i-circle-flags-is' },
    { value: 'AX', label: 'Islas Aland', code: '358', maskPhone: '', icon: 'i-circle-flags-ax' },
    { value: 'KY', label: 'Islas Caiman', code: '1', maskPhone: '', icon: 'i-circle-flags-ky' },
    { value: 'CC', label: 'Islas Cocos (Keeling)', code: '61', maskPhone: '', icon: 'i-circle-flags-cc' },
    { value: 'CK', label: 'Islas Cook', code: '682', maskPhone: '', icon: 'i-circle-flags-ck' },
    { value: 'FO', label: 'Islas Feroe', code: '298', maskPhone: '', icon: 'i-circle-flags-fo' },
    { value: 'HM', label: 'Islas Heard y Mcdonald', code: '672', maskPhone: '', icon: 'i-circle-flags-hm' },
    { value: 'FK', label: 'Islas Malvinas (Falkland)', code: '500', maskPhone: '', icon: 'i-circle-flags-fk' },
    { value: 'MP', label: 'Islas Marianas Del Norte', code: '1', maskPhone: '', icon: 'i-circle-flags-mp' },
    { value: 'MH', label: 'Islas Marshall', code: '692', maskPhone: '', icon: 'i-circle-flags-mh' },
    { value: 'SB', label: 'Islas Salomon', code: '677', maskPhone: '', icon: 'i-circle-flags-sb' },
    { value: 'TC', label: 'Islas Turcas y Caicos', code: '1', maskPhone: '', icon: 'i-circle-flags-tc' },
    { value: 'UM', label: 'Islas Ultramarinas Menores de Los Estados Unidos', code: '246', maskPhone: '', icon: 'i-circle-flags-um' },
    { value: 'VG', label: 'Islas Virgenes (Britanicas)', code: '1', maskPhone: '', icon: 'i-circle-flags-vg' },
    { value: 'IL', label: 'Israel', code: '972', maskPhone: '', icon: 'i-circle-flags-il' },
    { value: 'IT', label: 'Italia', code: '39', maskPhone: '### #### ###', icon: 'i-circle-flags-it' },
    { value: 'JM', label: 'Jamaica', code: '1', maskPhone: '', icon: 'i-circle-flags-jm' },
    { value: 'JP', label: 'Japon', code: '81', maskPhone: '### ### ####', icon: 'i-circle-flags-jp' },
    { value: 'JE', label: 'Jersey', code: '44', maskPhone: '', icon: 'i-circle-flags-je' },
    { value: 'JO', label: 'Jordania', code: '962', maskPhone: '', icon: 'i-circle-flags-jo' },
    { value: 'KE', label: 'Kenia', code: '254', maskPhone: '### ### ###', icon: 'i-circle-flags-ke' },
    { value: 'KG', label: 'Kirguistan', code: '996', maskPhone: '', icon: 'i-circle-flags-kg' },
    { value: 'KI', label: 'Kiribati', code: '686', maskPhone: '', icon: 'i-circle-flags-ki' },
    { value: 'KW', label: 'Kuwait', code: '965', maskPhone: '', icon: 'i-circle-flags-kw' },
    { value: 'LS', label: 'Lesoto', code: '266', maskPhone: '', icon: 'i-circle-flags-ls' },
    { value: 'LV', label: 'Letonia', code: '371', maskPhone: '', icon: 'i-circle-flags-lv' },
    { value: 'LB', label: 'Libano', code: '961', maskPhone: '', icon: 'i-circle-flags-lb' },
    { value: 'LR', label: 'Liberia', code: '231', maskPhone: '', icon: 'i-circle-flags-lr' },
    { value: 'LY', label: 'Libia', code: '218', maskPhone: '', icon: 'i-circle-flags-ly' },
    { value: 'LI', label: 'Liechtenstein', code: '423', maskPhone: '', icon: 'i-circle-flags-li' },
    { value: 'LT', label: 'Lituania', code: '370', maskPhone: '', icon: 'i-circle-flags-lt' },
    { value: 'LU', label: 'Luxemburgo', code: '352', maskPhone: '', icon: 'i-circle-flags-lu' },
    { value: 'MO', label: 'Macao', code: '853', maskPhone: '', icon: 'i-circle-flags-mo' },
    { value: 'MK', label: 'Macedonia Del Norte', code: '389', maskPhone: '', icon: 'i-circle-flags-mk' },
    { value: 'MG', label: 'Madagascar', code: '261', maskPhone: '', icon: 'i-circle-flags-mg' },
    { value: 'MY', label: 'Malasia', code: '60', maskPhone: '', icon: 'i-circle-flags-my' },
    { value: 'MW', label: 'Malaui', code: '265', maskPhone: '', icon: 'i-circle-flags-mw' },
    { value: 'MV', label: 'Maldivas', code: '960', maskPhone: '', icon: 'i-circle-flags-mv' },
    { value: 'ML', label: 'Mali', code: '223', maskPhone: '', icon: 'i-circle-flags-ml' },
    { value: 'MT', label: 'Malta', code: '356', maskPhone: '', icon: 'i-circle-flags-mt' },
    { value: 'MA', label: 'Marruecos', code: '212', maskPhone: '', icon: 'i-circle-flags-ma' },
    { value: 'MQ', label: 'Martinica', code: '596', maskPhone: '', icon: 'i-circle-flags-mq' },
    { value: 'MU', label: 'Mauricio', code: '230', maskPhone: '', icon: 'i-circle-flags-mu' },
    { value: 'MR', label: 'Mauritania', code: '222', maskPhone: '', icon: 'i-circle-flags-mr' },
    { value: 'YT', label: 'Mayotte', code: '262', maskPhone: '', icon: 'i-circle-flags-yt' },
    { value: 'MX', label: 'Mexico', code: '52', maskPhone: '## #### ####', icon: 'i-circle-flags-mx' },
    { value: 'FM', label: 'Micronesia (Estados Federados De)', code: '691', maskPhone: '', icon: 'i-circle-flags-fm' },
    { value: 'MD', label: 'Moldavia (Republica De)', code: '373', maskPhone: '', icon: 'i-circle-flags-md' },
    { value: 'MC', label: 'Monaco', code: '377', maskPhone: '', icon: 'i-circle-flags-mc' },
    { value: 'MN', label: 'Mongolia', code: '976', maskPhone: '', icon: 'i-circle-flags-mn' },
    { value: 'ME', label: 'Montenegro', code: '382', maskPhone: '', icon: 'i-circle-flags-me' },
    { value: 'MS', label: 'Montserrat', code: '1', maskPhone: '', icon: 'i-circle-flags-ms' },
    { value: 'MZ', label: 'Mozambique', code: '258', maskPhone: '', icon: 'i-circle-flags-mz' },
    { value: 'MM', label: 'Myanmar', code: '95', maskPhone: '# ### ###', icon: 'i-circle-flags-mm' },
    { value: 'NA', label: 'Namibia', code: '264', maskPhone: '', icon: 'i-circle-flags-na' },
    { value: 'NR', label: 'Nauru', code: '674', maskPhone: '', icon: 'i-circle-flags-nr' },
    { value: 'NP', label: 'Nepal', code: '977', maskPhone: '', icon: 'i-circle-flags-np' },
    { value: 'NI', label: 'Nicaragua', code: '505', maskPhone: '', icon: 'i-circle-flags-ni' },
    { value: 'NE', label: 'Niger', code: '227', maskPhone: '', icon: 'i-circle-flags-ne' },
    { value: 'NG', label: 'Nigeria', code: '234', maskPhone: '', icon: 'i-circle-flags-ng' },
    { value: 'NU', label: 'Niue', code: '683', maskPhone: '', icon: 'i-circle-flags-nu' },
    { value: 'NO', label: 'Noruega', code: '47', maskPhone: '', icon: 'i-circle-flags-no' },
    { value: 'NC', label: 'Nueva Caledonia', code: '687', maskPhone: '', icon: 'i-circle-flags-nc' },
    { value: 'NZ', label: 'Nueva Zelanda', code: '64', maskPhone: '', icon: 'i-circle-flags-nz' },
    { value: 'OM', label: 'Oman', code: '968', maskPhone: '', icon: 'i-circle-flags-om' },
    { value: 'NL', label: 'Paises Bajos', code: '31', maskPhone: '## ### ####', icon: 'i-circle-flags-nl' },
    { value: 'PK', label: 'Pakistan', code: '92', maskPhone: '', icon: 'i-circle-flags-pk' },
    { value: 'PW', label: 'Palaos', code: '680', maskPhone: '', icon: 'i-circle-flags-pw' },
    { value: 'PS', label: 'Palestina, Estado de', code: '970', maskPhone: '', icon: 'i-circle-flags-ps' },
    { value: 'PA', label: 'Panama', code: '507', maskPhone: '', icon: 'i-circle-flags-pa' },
    { value: 'PG', label: 'Papua Nueva Guinea', code: '675', maskPhone: '', icon: 'i-circle-flags-pg' },
    { value: 'PY', label: 'Paraguay', code: '595', maskPhone: '', icon: 'i-circle-flags-py' },
    { value: 'PE', label: 'Peru', code: '51', maskPhone: '### ### ###', icon: 'i-circle-flags-pe' },
    { value: 'PN', label: 'Pitcairn', code: '64', maskPhone: '', icon: 'i-circle-flags-pn' },
    { value: 'PF', label: 'Polinesia Francesa', code: '689', maskPhone: '', icon: 'i-circle-flags-pf' },
    { value: 'PL', label: 'Polonia', code: '48', maskPhone: '### ### ###', icon: 'i-circle-flags-pl' },
    { value: 'PT', label: 'Portugal', code: '351', maskPhone: '## ### ####', icon: 'i-circle-flags-pt' },
    { value: 'PR', label: 'Puerto Rico', code: '1', maskPhone: '', icon: 'i-circle-flags-pr' },
    { value: 'GB', label: 'Reino Unido de Gran Bretana E Irlanda Del Norte', code: '44', maskPhone: '#### ### ###', icon: 'i-circle-flags-gb' },
    { value: 'SY', label: 'Republica Arabe Siria', code: '963', maskPhone: '', icon: 'i-circle-flags-sy' },
    { value: 'CF', label: 'Republica Centroafricana', code: '236', maskPhone: '', icon: 'i-circle-flags-cf' },
    { value: 'CZ', label: 'Republica Checa', code: '420', maskPhone: '', icon: 'i-circle-flags-cz' },
    { value: 'XK', label: 'Republica de Kosovo', code: '383', maskPhone: '', icon: 'i-circle-flags-xk' },
    { value: 'LA', label: 'Republica Democratica Popular Lao', code: '856', maskPhone: '', icon: 'i-circle-flags-la' },
    { value: 'DO', label: 'Republica Dominicana', code: '1', maskPhone: '', icon: 'i-circle-flags-do' },
    { value: 'RE', label: 'Reunion', code: '262', maskPhone: '', icon: 'i-circle-flags-re' },
    { value: 'RW', label: 'Ruanda', code: '250', maskPhone: '', icon: 'i-circle-flags-rw' },
    { value: 'RO', label: 'Rumania', code: '40', maskPhone: '', icon: 'i-circle-flags-ro' },
    { value: 'EH', label: 'Sahara Occidental', code: '212', maskPhone: '', icon: 'i-circle-flags-eh' },
    { value: 'WS', label: 'Samoa', code: '685', maskPhone: '', icon: 'i-circle-flags-ws' },
    { value: 'AS', label: 'Samoa Americana', code: '1', maskPhone: '', icon: 'i-circle-flags-as' },
    { value: 'BL', label: 'San Bartolome', code: '590', maskPhone: '', icon: 'i-circle-flags-bl' },
    { value: 'KN', label: 'San Cristobal y Nieves', code: '1', maskPhone: '', icon: 'i-circle-flags-kn' },
    { value: 'SM', label: 'San Marino', code: '378', maskPhone: '', icon: 'i-circle-flags-sm' },
    { value: 'MF', label: 'San Martin (Parte Francesa)', code: '590', maskPhone: '', icon: 'i-circle-flags-mf' },
    { value: 'PM', label: 'San Pedro y Miquelon', code: '508', maskPhone: '', icon: 'i-circle-flags-pm' },
    { value: 'VC', label: 'San Vicente y Las Granadinas', code: '1', maskPhone: '', icon: 'i-circle-flags-vc' },
    { value: 'SH', label: 'Santa Elena, Ascension y Tristan de Acuna', code: '290', maskPhone: '', icon: 'i-circle-flags-sh' },
    { value: 'LC', label: 'Santa Lucia', code: '1', maskPhone: '', icon: 'i-circle-flags-lc' },
    { value: 'ST', label: 'Santo Tome y Principe', code: '239', maskPhone: '', icon: 'i-circle-flags-st' },
    { value: 'SN', label: 'Senegal', code: '221', maskPhone: '', icon: 'i-circle-flags-sn' },
    { value: 'RS', label: 'Serbia', code: '381', maskPhone: '', icon: 'i-circle-flags-rs' },
    { value: 'SC', label: 'Seychelles', code: '248', maskPhone: '', icon: 'i-circle-flags-sc' },
    { value: 'SL', label: 'Sierra Leona', code: '232', maskPhone: '', icon: 'i-circle-flags-sl' },
    { value: 'SG', label: 'Singapur', code: '65', maskPhone: '', icon: 'i-circle-flags-sg' },
    { value: 'SX', label: 'Sint Maarten (Parte Holandesa)', code: '1', maskPhone: '', icon: 'i-circle-flags-sx' },
    { value: 'SO', label: 'Somalia', code: '252', maskPhone: '', icon: 'i-circle-flags-so' },
    { value: 'LK', label: 'Sri Lanka', code: '94', maskPhone: '', icon: 'i-circle-flags-lk' },
    { value: 'ZA', label: 'Sudafrica', code: '27', maskPhone: '## ### ####', icon: 'i-circle-flags-za' },
    { value: 'SD', label: 'Sudan', code: '249', maskPhone: '### ### ###', icon: 'i-circle-flags-sd' },
    { value: 'SS', label: 'Sudan Del Sur', code: '211', maskPhone: '', icon: 'i-circle-flags-ss' },
    { value: 'SE', label: 'Suecia', code: '46', maskPhone: '## ### ###', icon: 'i-circle-flags-se' },
    { value: 'CH', label: 'Suiza', code: '41', maskPhone: '## ### ####', icon: 'i-circle-flags-ch' },
    { value: 'SR', label: 'Surinam', code: '597', maskPhone: '### ####', icon: 'i-circle-flags-sr' },
    { value: 'SJ', label: 'Svalbard y Jan Mayen', code: '47', maskPhone: '### ## ###', icon: 'i-circle-flags-sj' },
    { value: 'TH', label: 'Tailandia', code: '66', maskPhone: '# ### ####', icon: 'i-circle-flags-th' },
    { value: 'TW', label: 'Taiwan', code: '886', maskPhone: '# #### ####', icon: 'i-circle-flags-tw' },
    { value: 'TZ', label: 'Tanzania, Republica Unida de', code: '255', maskPhone: '### ### ###', icon: 'i-circle-flags-tz' },
    { value: 'TJ', label: 'Tayikistan', code: '992', maskPhone: '### ## ####', icon: 'i-circle-flags-tj' },
    { value: 'IO', label: 'Territorio Britanico Del Oceano Indico', code: '246', maskPhone: '### ####', icon: 'i-circle-flags-io' },
    { value: 'TF', label: 'Territorios Australes Franceses', code: '262', maskPhone: '#### ####', icon: 'i-circle-flags-tf' },
    { value: 'TL', label: 'Timor-Leste', code: '670', maskPhone: '### ####', icon: 'i-circle-flags-tl' },
    { value: 'TG', label: 'Togo', code: '228', maskPhone: '### ####', icon: 'i-circle-flags-tg' },
    { value: 'TK', label: 'Tokelau', code: '690', maskPhone: '### ####', icon: 'i-circle-flags-tk' },
    { value: 'TO', label: 'Tonga', code: '676', maskPhone: '### ####', icon: 'i-circle-flags-to' },
    { value: 'TT', label: 'Trinidad y Tobago', code: '1', maskPhone: '### ####', icon: 'i-circle-flags-tt' },
    { value: 'TN', label: 'Tunez', code: '216', maskPhone: '## ### ###', icon: 'i-circle-flags-tn' },
    { value: 'TM', label: 'Turkmenistan', code: '993', maskPhone: '## ### ###', icon: 'i-circle-flags-tm' },
    { value: 'TR', label: 'Turquia', code: '90', maskPhone: '### ### ####', icon: 'i-circle-flags-tr' },
    { value: 'TV', label: 'Tuvalu', code: '688', maskPhone: '### ####', icon: 'i-circle-flags-tv' },
    { value: 'UA', label: 'Ucrania', code: '380', maskPhone: '## ### ####', icon: 'i-circle-flags-ua' },
    { value: 'UG', label: 'Uganda', code: '256', maskPhone: '### ### ###', icon: 'i-circle-flags-ug' },
    { value: 'UY', label: 'Uruguay', code: '598', maskPhone: '### ####', icon: 'i-circle-flags-uy' },
    { value: 'UZ', label: 'Uzbekistan', code: '998', maskPhone: '## ### ####', icon: 'i-circle-flags-uz' },
    { value: 'VU', label: 'Vanuatu', code: '678', maskPhone: '### ####', icon: 'i-circle-flags-vu' },
    { value: 'VE', label: 'Venezuela (Republica Bolivariana De)', code: '58', maskPhone: '### ### ####', icon: 'i-circle-flags-ve' },
    { value: 'VN', label: 'Vietnam', code: '84', maskPhone: '# ### ####', icon: 'i-circle-flags-vn' },
    { value: 'WF', label: 'Wallis y Futuna', code: '681', maskPhone: '## ####', icon: 'i-circle-flags-wf' },
    { value: 'YE', label: 'Yemen', code: '967', maskPhone: '### ### ###', icon: 'i-circle-flags-ye' },
    { value: 'DJ', label: 'Yibuti', code: '253', maskPhone: '## ### ###', icon: 'i-circle-flags-dj' },
    { value: 'ZM', label: 'Zambia', code: '260', maskPhone: '### ### ###', icon: 'i-circle-flags-zm' },
    { value: 'ZW', label: 'Zimbabue', code: '263', maskPhone: '## ### ###', icon: 'i-circle-flags-zw' },
  ],
  currency: [
    { value: 'USD', label: 'USD', title: 'USD - Dólar Americano', description: 'Dólar americano', color: 'green' },
    { value: 'MXN', label: 'MXN', title: 'MXN - Peso Mexicano', description: 'Peso mexicano', color: 'blue' },
  ],
  unit: [
    { value: 'pieza', label: 'Pieza', description: 'Pieza.', color: 'blue' },
    { value: 'm2', label: 'M2', description: 'Metro cuadrado.', color: 'green' },
    { value: 'hr', label: 'Hora', description: 'Hora.', color: 'amber' },
    { value: 'ml', label: 'ML', description: 'Metro lineal.', color: 'orange' },
  ],
  i18n: [
    { value: 'es-MX', label: 'Español' },
    { value: 'en-US', label: 'English' },
  ],
  priority: [
    { value: '1', label: 'Baja', description: 'Prioridad baja. Puede ser atendida en un despues sin generar problemas.', icon: 'i-mdi-arrow-down-circle', color: 'green' },
    { value: '2', label: 'Media', description: 'Prioridad media. Requiere atención en un tiempo razonable, pero no es urgente.', icon: 'i-mdi-arrow-right-circle', color: 'amber' },
    { value: '3', label: 'Alta', description: 'Prioridad alta. Necesita atención lo antes posible.', icon: 'i-mdi-arrow-up-circle', color: 'orange' },
    { value: '4', label: 'Crítica', description: 'Prioridad crítica. Requiere atención inmediata.', icon: 'i-mdi-alert', color: 'red' },
  ],
  views: [
    { value: 'grid', label: 'Tabla', icon: 'i-mdi-table' },
    { value: 'cards', label: 'Tarjetas', icon: 'i-mdi-view-module' },
    { value: 'gallery', label: 'Galería', icon: 'i-mdi-view-grid' },
    { value: 'kanban', label: 'Kanban', icon: 'i-mdi-view-column' },
    { value: 'list', label: 'Lista', icon: 'i-mdi-view-list' },
    { value: 'calendar', label: 'Calendario', icon: 'i-mdi-calendar' },
  ],
} satisfies Options

export const ICONS: Record<Row, `i-mdi-${string}`> = optionsShared.tableName.reduce((acc, option) => ({
  ...acc,
  [option.value as Row]: option.icon as `i-mdi-${string}`,
}), {} as Record<Row, `i-mdi-${string}`>)

// Helper functions
// transformations:
/** Simple heuristic to convert an object to an Option (usage: ITEMS.map(toOption)) */
export const toOption = ({
  id,
  key,
  name,
  uid,
  title,
  description,
  i18n,
  ...row
}: Record<string, unknown>): Option => {
  const value = (row?.value ?? id ?? key) as string
  let label = row?.label ?? (name && uid && name !== uid ? `${name} (${uid})` : `${name ?? title ?? uid}`)

  if (!label) {
    const { es = {}, en = {} } = (i18n ?? {}) as Record<string, Record<string, string>>
    if (es) label = (es.name ?? es.title ?? es.description ?? es.label ?? value) as string
    else if (en) label = (en.name ?? en.title ?? en.description ?? en.label ?? value) as string
  }
  const src = row?.image as string
  const avatar = src ? { src, alt: label } : { alt: label } // else return `alt` to render initials
  const color = row?.color as string
  const disabled = !!row?.archivedAt
  return { ...row, value, label, avatar, color, description, disabled }
}

export const toFilteredOptions = (
  data: Record<string, unknown>[] = [],
  itemKey: string,
): Option[] => {
  const allOptions = data
    .map(item => item[itemKey])
    .filter(Boolean)
    .map(item => ({
      value: item?.id,
      label: item?.name,
      uid: item?.uid,
      avatar: item?.avatar ?? item?.image ? { src: item?.image as string, alt: item?.name ?? item?.uid } : { alt: item?.name ?? item?.uid },
      disabled: !!item?.archivedAt,
    })) as Option[]

  const seenKeys = new Set()
  const uniqueOptions: Option[] = []

  allOptions.forEach((item) => {
    if (!seenKeys.has(item.value)) {
      seenKeys.add(item.value)
      uniqueOptions.push(item)
    }
  })

  return uniqueOptions
}

// WORKAROUND: omit avatar for performance until @nuxt/ui-pro@v3 lands with fix
// see https://github.com/nuxt/ui/issues/388 and https://github.com/nuxt/ui/issues/280
export const toOptionWithoutAvatar = (row: Record<string, unknown>): Option => {
  const { avatar, ...rest } = toOption(row)
  return rest
}

// avoids calling .find() for each row (O(n^2) -> O(n)) and uses a
// Map instead of a plain object (via .reduce()) for much better performance
export const toMapByKey = <T extends object>(array: T[] = [], key: keyof T): Map<T[keyof T], T> => {
  const map = new Map<T[keyof T], T>()
  if (!Array.isArray(array)) return map
  for (const item of array) {
    const value = get(item, key)
    if (value) map.set(value, item)
  }
  return map
}

export const toMapByNestedKey = <T extends object>(
  array: T[] = [],
  ...keys: (keyof T)[] // Accept multiple keys as rest parameters
): Map<T[keyof T], T> => {
  const map = new Map<T[keyof T], T>()
  if (!Array.isArray(array)) return map
  const getNestedValue = (item: T, keys: (keyof T)[]): any => {
    return keys.reduce((obj, key) => obj?.[key], item)
  }
  for (const item of array) {
    const value = getNestedValue(item, keys)
    if (value !== undefined && value !== null) {
      if (!map.has(value)) {
        map.set(value, [])
      }
      map.get(value)?.push(item)
    }
  }

  return map
}

export const toMapByGroupKey = <T extends object, K extends keyof T>(array: T[] = [], key: K): Map<T[K], T[]> => {
  const map = new Map<T[K], T[]>()
  if (!Array.isArray(array)) return map

  for (const item of array) {
    const value = item[key]

    if (value !== undefined && value !== null) {
      if (!map.has(value)) {
        map.set(value, [])
      }
      map.get(value)?.push(item)
    }
  }

  return map
}

export const toMapByKeyArrayValue = <T extends object, K extends keyof T>(
  array: T[] = [],
  key: K,
): Map<T[K] extends (infer U)[] ? U : never, T[]> => {
  const map = new Map<T[K] extends (infer U)[] ? U : never, T[]>()

  for (const item of array) {
    const value = item[key]
    // Ensure value is an array
    if (Array.isArray(value)) {
      for (const subValue of value) {
        if (!map.has(subValue)) {
          map.set(subValue, [])
        }
        map.get(subValue)?.push(item)
      }
    }
  }

  return map
}

export const toMapByKeyFromObject = <T extends object>(object: Record<string, T[]>, key: keyof T): Record<string, Map<T[keyof T], T>> => {
  const map = {} as Record<string, Map<T[keyof T], T>>
  for (const [property, array] of Object.entries(object)) {
    map[property] = toMapByKey(array, key)
  }
  return map
}

// alternative to path.join() of node.js for the browser
export const pathJoin = (...segments: string[]): string => {
  return segments.map(segment => segment.replace(/(^\/+|\/+$)/g, '')).filter(Boolean).join('/')
}

export const getUrl = (pathname: string): URL => {
  return new URL(pathname, window.location.origin)
}

export const getFileUrl = (file: File | null | undefined): URL => {
  if (!file?.pathname) return
  const pathname = pathJoin('/api/blob/', file.pathname)
  return new URL(pathname, window.location.origin)
}
