import type { Options } from '#netzo/shared/types/core'
import { optionsShared as optionsSharedNetzo } from '#netzo/utils/core'

export const optionsShared = {
  ...optionsSharedNetzo,
  priority: [
    { value: '1', label: 'Baja', description: 'Prioridad baja. Puede ser atendida en un despues sin generar problemas.', icon: 'i-mdi-arrow-down-circle', color: 'green' },
    { value: '2', label: 'Media', description: 'Prioridad media. Requiere atención en un tiempo razonable, pero no es urgente.', icon: 'i-mdi-arrow-right-circle', color: 'amber' },
    { value: '3', label: 'Alta', description: 'Prioridad alta. Necesita atención lo antes posible.', icon: 'i-mdi-arrow-up-circle', color: 'orange' },
    { value: '4', label: 'Crítica', description: 'Prioridad crítica. Requiere atención inmediata.', icon: 'i-mdi-alert', color: 'red' },
  ],
  unit: [
    { value: 'pieza', label: 'Pieza', description: 'Pieza.', color: 'blue' },
    { value: 'm2', label: 'M2', description: 'Metro cuadrado.', color: 'green' },
    { value: 'hr', label: 'Hora', description: 'Hora.', color: 'amber' },
    { value: 'ml', label: 'ML', description: 'Metro lineal.', color: 'orange' },
  ],
  tableName: [
    ...optionsSharedNetzo.tableName,
    {
      value: 'installationorders',
      label: 'Órdenes de Instalación',
      description: 'Las Órdenes de Instalación documentan trabajos o servicios programados para clientes.',
      icon: 'i-mdi-tools',
    },
    {
      value: 'messages',
      label: 'Mensajes',
      description: 'Los Mensajes son comunicaciones internas o externas enviadas desde el sistema.',
      icon: 'i-mdi-android-messages',
    },
  ],
  nps: [
    { value: 1, label: 'Extremadamente insatisfecho' },
    { value: 2, label: 'Muy insatisfecho' },
    { value: 3, label: 'Insatisfecho' },
    { value: 4, label: 'Ligeramente insatisfecho' },
    { value: 5, label: 'Neutral' },
    { value: 6, label: 'Ligeramente satisfecho' },
    { value: 7, label: 'Satisfecho' },
    { value: 8, label: 'Muy satisfecho' },
    { value: 9, label: 'Extremadamente satisfecho' },
    { value: 10, label: 'Completamente satisfecho' },
  ],
} satisfies Options
